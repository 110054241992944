<template>
  <div id="app">
    <h1>{{ greeting }}</h1>
  </div>
</template>

<script>
import { createApp } from 'vue'

export default {
  data() {
    return {
      user: null,
      greeting: 'Загрузка...'
    }
  },
  mounted() {
    this.initTelegramWebApp()
  },
  methods: {
    initTelegramWebApp() {
      const script = document.createElement('script')
      script.src = 'https://telegram.org/js/telegram-web-app.js'
      script.async = true
      script.onload = () => {
        window.Telegram.WebApp.ready()
        this.user = window.Telegram.WebApp.initDataUnsafe.user
        this.updateGreeting()
      }
      document.head.appendChild(script)
    },
    updateGreeting() {
      if (this.user && this.user.first_name) {
        this.greeting = `Привет, ${this.user.first_name}!`
      } else {
        this.greeting = 'Привет, гость!'
      }
    }
  }
}

createApp({}).mount('#app')
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  text-align: center;
  margin-top: 50px;
}
</style>
